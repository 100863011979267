import {
  Autocomplete,
  Avatar,
  Button,
  Chip,
  CircularProgress,
  FormHelperText,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { onInvitation } from "api/onInvitation";
import ConfirmDeleteDialog from "components/modals/ConfirmDeleteDialog";
import { format } from "date-fns";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AutocompleteTextFieldStyleOverrides,
  Root,
  StyledTableCell,
  StyledTableRow,
} from "settings/styleOverrides";
import withReducer from "store/withReducer";
import _ from "utils/lodash";
import Utilities from "utils/utilities";
import { selectMembers } from "views/members/store/membersSlice";
import WidgetWrapper from "../../components/WidgetWrapper";
import EmptyPanel from "../../components/display/EmptyPanel";
import SearchBar from "../../components/header/SearchBar";
import invitationSlice, {
  addInvitedMembers,
  getInvitedMembers,
  removeInvitedMember,
} from "./store/invitationSlice";
import { Box } from "@mui/system";
import DeleteIcon from "components/svgs/icons/general/DeleteIcon";
import Invite from "components/svgs/icons/navbars/Invite";

// TOOD: Use yup form for this page
const validEmailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

const InviteUsers = (props) => {
  const { onboarding, widget, removeWidget } = props;
  const dispatch = useDispatch();
  const autocompleteTextFieldStyleOverrides =
    AutocompleteTextFieldStyleOverrides();

  const [err, setError] = useState({ status: "", message: "" });
  const [invitedMembers, setInvitedMembers] = useState([]);
  const [emailsArray, setEmailsArray] = useState([]);
  const [loadingSpinner, setLoadingSpinner] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const user = useSelector(({ auth }) => auth.user);
  const members = useSelector(selectMembers);
  const darkMode = useSelector(
    ({ entities }) => entities.settings.current.darkMode
  );
  let error = false;

  useEffect(() => {
    if (members.length === 0) return;
    fetchData();
  }, [members]);

  useEffect(() => {
    fetchData();
  }, [onboarding]);

  // TODO: For now do client side filtering
  useEffect(() => {
    setFilteredData(Utilities.getFilteredArray(invitedMembers, searchQuery));
  }, [members, invitedMembers, searchQuery]);

  const fetchData = () => {
    dispatch(getInvitedMembers(user))
      .then((res) => {
        const data = res?.payload || [];
        mapData(data);
      })
      .catch(() => setLoadingSpinner(false));
  };

  const mapData = (data) => {
    const list = [];
    if (members?.length > 1 && data.length > 0) {
      data.forEach((item) => {
        const member = Utilities.findMemberByEmail(members, item.email);
        list.push({
          status: item.status,
          email: item.email,
          modifiedDate: item.modifiedDate,
          displayName: member?.displayName,
        });
      });
    }
    setInvitedMembers(list);
    setLoadingSpinner(false);
  };

  const submitHandler = (e) => {
    e?.preventDefault();
    if (isValid()) return;

    const emailObjArr = [];
    emailsArray.map((e) =>
      emailObjArr.push({ email: e.trim(), id: Utilities.generateGUID() })
    );

    if (e && onboarding) {
      setInvitedMembersStatus();
      return;
    }

    // On Invitation Handler
    sendInvitation(emailObjArr);
  };

  const isValid = () => {
    if (emailsArray.length === 0) {
      setError({ status: "error", message: "No emails to send invite to" });
      error = true;
    }

    emailsArray.forEach((e) => {
      if (!validEmailRegex.test(e.trim())) {
        setError({
          status: "error",
          message: "Please enter valid email address and hit enter",
        });
        error = true;
      }
      const duplicateEntries =
        invitedMembers.findIndex((x) => x.email === e.trim()) !== -1;
      if (duplicateEntries) {
        setError({
          status: "error",
          message: "One of members from list is already invited.",
        });
        error = true;
      }
    });

    return error;
  };

  const setInvitedMembersStatus = () => {
    const newInvitedMembers = invitedMembers.map((obj) => {
      if (emailsArray.includes(obj.email)) {
        emailsArray.pop(obj.email);
        obj.status = "pending";
      }
      return obj;
    });

    const newCurrentPendingArr = emailsArray.map((e) => ({
      email: e.trim(),
      status: "pending",
      modifiedDate: new Date(),
    }));
    setInvitedMembers([...newCurrentPendingArr, ...newInvitedMembers]);

    return newCurrentPendingArr;
  };

  const sendInvitation = (emailObjArr) => {
    const sendData = {
      data: emailObjArr,
      workspaceId: user.data?.currentWorkspace,
    };
    setError(false);
    setLoadingSpinner(true);
    onInvitation(sendData)
      .then((res) => {
        setLoadingSpinner(false);

        if (res.body.error) {
          const body =
            res.statusCode === 426
              ? res.body
              : {
                  error: { message: "Invitation Failed " },
                };
          Utilities.errorNotification(user, dispatch, body);
        } else {
          const newCurrentPendingArr = setInvitedMembersStatus();
          const data = [...newCurrentPendingArr, ...invitedMembers];
          dispatch(addInvitedMembers({ user, data }));
          setEmailsArray([]);
        }
      })
      .catch((error) => {
        Utilities.errorNotification(user, dispatch, error);
        setLoadingSpinner(false);
      });
  };

  const resendInvite = (email) => {
    const emailObjArr = [{ email: email, id: Utilities.generateGUID() }];
    sendInvitation(emailObjArr);
  };

  const onDelete = (row) => {
    setSelectedRow(row);
    setConfirmDialog(true);
  };

  const removeMemberHandler = () => {
    setLoadingSpinner(true);
    const member = members.find((x) => x.email === selectedRow.email);
    const cloneInvitedMembers = [...invitedMembers];
    dispatch(removeInvitedMember(member))
      .then(() => {
        cloneInvitedMembers.forEach((x) => {
          if (x.email === member.email) {
            x.status = "deactivated";
          }
        });
        setInvitedMembers(cloneInvitedMembers);
      })
      .finally(() => setLoadingSpinner(false));
  };

  const pageLayout = useRef(null);
  const renderInvitedMembers = () => (
    <Root
      header={
        <>
          <h3 className="text-lg font-semibold text-gray-500 uppercase tracking-wide draggableCancel">
            Team members previously invited to join workspace
          </h3>
          <SearchBar
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
          />
        </>
      }
      content={
        <motion.div
          className="flex flex-wrap"
          variants={Utilities.containerConfig(0.1)}
          initial="hidden"
          animate="show"
        >
          {loadingSpinner ? (
            <CircularProgress color="info" className="m-auto" />
          ) : (
            <motion.div
              variants={Utilities.itemOpacityConfig(20)}
              className="widget flex w-full px-2 md:px-10 flex-col"
              initial="hidden"
              animate="show"
            >
              {!loadingSpinner && !invitedMembers.length ? (
                <EmptyPanel
                  mainClass={"mt-20"}
                  mainText={"No member, yet?"}
                  subText={"Invite members to begin collaborating on projects."}
                  imageSrc={"emptyMembers"}
                  imgAlt={"Empty Invitations"}
                  searchQuery={searchQuery}
                />
              ) : (
                <TableContainer className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
                  <Table
                    sx={{
                      minWidth: 500,
                      borderCollapse: "separate",
                      borderSpacing: "0px 1.5rem",
                    }}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Email Address</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell>Resend</StyledTableCell>
                        <StyledTableCell>Last Activity</StyledTableCell>
                        <StyledTableCell>Deactivate</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData.map((row, personIdx) => (
                        <StyledTableRow key={personIdx}>
                          <StyledTableCell className="min-w-0 flex-1 flex items-center space-x-3">
                            <div className="flex-shrink-0">
                              <Avatar
                                src="src"
                                alt={row.email}
                                className="ml-2 mt-1 mb-1 h-19 w-19 rounded-full"
                              />
                            </div>
                            {row.displayName ? (
                              <div className="min-w-0 flex-1">
                                <p
                                  className={`text-sm font-medium truncate ${
                                    darkMode ? "text-gray-100" : "text-gray-900"
                                  }`}
                                >
                                  {row.displayName}
                                </p>
                                <p className="text-sm font-medium text-gray-500 truncate">
                                  {row.email}
                                </p>
                              </div>
                            ) : (
                              <div className="min-w-0 flex-1">
                                <p
                                  className={`text-sm font-medium truncate ${
                                    darkMode ? "text-gray-100" : "text-gray-900"
                                  }`}
                                >
                                  {row.email}
                                </p>
                              </div>
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            <Button
                              type="button"
                              className="inline-flex items-center p-0 border border-transparent rounded-full bg-gray-100 hover:bg-gray-200"
                            >
                              <span className="text-sm font-medium text-gray-900">
                                <Chip
                                  label={_.capitalize(row.status)}
                                  className="font-semibold text-sm text-white w-100"
                                  style={{
                                    backgroundColor:
                                      (row.status === "pending" && "orange") ||
                                      (row.status === "accepted" && "green") ||
                                      (row.status === "deactivated" &&
                                        "lightGray"),
                                  }}
                                />
                                <span className="sr-only">{row.email}</span>
                              </span>
                            </Button>
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.status == "pending" ||
                            row.status == "deactivated" ? (
                              <Button
                                variant="outlined"
                                size="small"
                                className="rounded-md border-none text-primaryBlue"
                                onClick={() => resendInvite(row.email)}
                              >
                                Invite
                              </Button>
                            ) : (
                              "-"
                            )}
                          </StyledTableCell>
                          <StyledTableCell className="whitespace-nowrap">
                            {Utilities.formatDate(
                              row.modifiedDate,
                              "yyyy-MM-dd hh:mm a",
                              format
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.status == "accepted" ? (
                              <Box
                                className="cursor-pointer text-center"
                                onClick={() => onDelete(row)}
                              >
                                <DeleteIcon />
                              </Box>
                            ) : (
                              "-"
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </motion.div>
          )}
        </motion.div>
      }
    />
  );

  if (widget) {
    return (
      <WidgetWrapper widget={widget} removeWidget={removeWidget}>
        <div className="flex flex-col">{renderInvitedMembers()}</div>
      </WidgetWrapper>
    );
  }

  if (!filteredData) {
    return null;
  }

  return (
    <Root
      onboarding={onboarding}
      content={
        <div className="relative w-full p-8">
          <div className="max-w-4xl mx-auto">
            <div className="text-center">
              <Box className="flex justify-center">
                <Invite height={57} width={57} color="text-gray-400" />
              </Box>
              <Typography className="mt-5 text-2xl font-medium">
                Invite team members
              </Typography>
              <Typography className="mt-3 text-lg text-mute">
                You can invite team members to join your workspace. As the owner
                of this owner, you can manage team member permissions.
              </Typography>
            </div>
            <form action="#" className="mt-14 flex" onSubmit={submitHandler}>
              <label htmlFor="email" className="sr-only">
                Email addresses
              </label>

              <Autocomplete
                multiple
                value={emailsArray}
                onChange={(e, value) => {
                  setEmailsArray(value);
                  setError({ error: "", message: "" });
                }}
                id="tags-standard"
                options={[]}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                sx={{ width: "750px" }}
                renderInput={(params) => (
                  <>
                    <TextField
                      autoComplete="off"
                      error={err?.status === "error"}
                      placeholder="Type email address and hit enter"
                      {...params}
                      variant="outlined"
                      fullWidth
                      sx={autocompleteTextFieldStyleOverrides}
                    />
                    {err?.status === "error" && (
                      <FormHelperText
                        error={err}
                        required
                        className="ml-14 text-xl"
                      >
                        {err?.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
              <Button
                size="medium"
                variant="contained"
                color="secondary"
                type="submit"
                className="bg-primaryBlue hover:bg-primaryBlueLight text-white ml-3.5 w-36 border border-transparent text-lg rounded-md shadow-sm"
                disabled={loadingSpinner}
              >
                Invite
              </Button>
            </form>
          </div>

          <div className="mt-20">{renderInvitedMembers()}</div>
          {confirmDialog && (
            <ConfirmDeleteDialog
              open={confirmDialog}
              onClose={() => setConfirmDialog(false)}
              deleteFunc={removeMemberHandler}
              message="Are you sure you want to remove this member from your workspace?"
            />
          )}
        </div>
      }
      ref={pageLayout}
    />
  );
};

export default withReducer("inviteApp", invitationSlice)(InviteUsers);
