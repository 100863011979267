import React from "react";

const AppCenter = () => {
  const className = "fill-primaryBlue";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="94.954"
      height="72.971"
      viewBox="0 0 94.954 72.971"
      className={className}
    >
      <g
        id="Group_45991"
        data-name="Group 45991"
        transform="translate(-1374 -479)"
      >
        <path
          id="Path_40132"
          data-name="Path 40132"
          d="M1228.118,479.6h-4.97c-.017-.37-.049-.733-.05-1.1q-.005-3.994,0-7.987c0-2.356-.629-3-2.954-3q-21.167,0-42.332,0-14.149,0-28.3,0c-2.531,0-3.107.566-3.107,3.054q0,19.4,0,38.795c0,2.255.635,2.886,2.927,2.887q24.873,0,49.749,0h1.536v9.852h-1.265q-27.383,0-54.768,0c-2.441,0-3.2-.76-3.2-3.212q0-26.357,0-52.715c0-2.349.8-3.149,3.158-3.149q40.163,0,80.328,0c2.408,0,3.246.83,3.248,3.2C1228.119,470.643,1228.118,475.054,1228.118,479.6Zm-46.365,37.556a3.006,3.006,0,0,0,2.979,3.072,3.1,3.1,0,0,0,3.015-3.059,3.036,3.036,0,0,0-2.944-3A2.971,2.971,0,0,0,1181.753,517.157Z"
          transform="translate(232.618 15.971)"
        />
        <path
          id="Path_40133"
          data-name="Path 40133"
          d="M1197.844,507.492q0-10.093,0-20.188,0-6.3,6.317-6.3,9.069,0,18.135,0c3.934,0,6.091,2.142,6.092,6.053q.009,20.188,0,40.376c0,3.825-2.188,6.017-6.023,6.025q-9.238.022-18.477,0c-3.865-.009-6.041-2.227-6.043-6.122Q1197.838,517.415,1197.844,507.492Zm2.34,17.482h25.838V489.5h-25.838Zm12.875,6.227a2.248,2.248,0,0,0,2.243-2.1,2.21,2.21,0,1,0-4.418-.1A2.333,2.333,0,0,0,1213.059,531.2Z"
          transform="translate(240.562 18.5)"
        />
        <path
          id="Path_40134"
          data-name="Path 40134"
          d="M1165.116,527.154c3.377-2.44,5.5-5.348,5.613-9.445h21.353c.088,4.04,1.993,7.1,5.524,9.445Z"
          transform="translate(235.957 23.664)"
        />
      </g>
    </svg>
  );
};

export default AppCenter;
