export const getQuote = async () => {
  const endpoint = "https://api.quotable.io/random?maxLength=40";
  const request = await fetch(endpoint);
  const response = await request.json();
  if (response.error) {
    return {
      statusCode: 404,
      body: response,
    };
  }
  return {
    statusCode: 200,
    body: response,
  };
};
